<script>
import Modal from "../modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import CustomTable from "@/components/tables/custom-table.vue";

const initialState = () => {
    return {
        submitted: false,
        inputFileDisabled: false,
        import_data: null,
        interval: null,
        table: {
            url: null,
            fields: []
        },
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        type: String,     
    },
    data() {
        return {
            ...initialState(),            
        };
    },
    components: {
        Modal,
        CustomTable
    },
    computed: {
        
    },
    methods: {
        async shown() {
            
        },
        confirm() {
            
            this.$bvModal.hide(this.modalId);
        },       
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        refreshTable() {
          console.log('refreshTable');
            this.$refs.previewImportTable.refresh();
        },
        setTableOptions() {

          this.table.url = `imports/${this.import_data?.id}/import-rows`;

          this.table.fields = [];

          Object.entries(this.import_data?.headers).forEach(([key, label]) => {
            console.log(`Clave: ${key}, Valor: ${label}`);
            this.table.fields.push({ key: key, label: label, sortable: false })
          });
        },
        async getPreviewImportFile() {

          try {
            const response = await api.get({
              url: `imports/${this.import_data?.id}`
            });

            this.import_data = response?.data?.data;
            this.validateClearInterval();
          }catch (error) {
              console.log(error);
          }
          
        },
        validateClearInterval(){
          if(this.import_data?.process_status > 1){
            clearInterval(this.interval);
            this.refreshTable();
          }
        },
        watchPreviewImportFile() {
          
          if(this.import_data?.process_status > 1){
            return;
          }
          const self = this;
          this.interval = setInterval(function () {
            self.getPreviewImportFile();
          }, 3000); 
        },
        async previewImportFile(file) {
            try {
              if(!file) return;
              this.inputFileDisabled = true;
              let formData = new FormData();
              formData.append('import_file', file);
              formData.append('type', this.type);

              const response = await api.post({
                url: 'imports',
                data: formData,
                config: {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
              });

              this.import_data = response?.data?.data;
              this.setTableOptions();
              this.watchPreviewImportFile();

            }catch (error) {
                console.log(error);
            }

            this.inputFileDisabled = false;
        }
    }
};
</script>

<template>
    <Modal :title="'Administrador de medios'" :id="modalId"  @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-sm-4">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">1. Selecciona el archivo a importar</h4>
              <div class="row">
                <div class="form-group col-12">
                    <input id="name" type="file" :disabled="inputFileDisabled" class="form-control" placeholder="Selecciona un archivo" @change="previewImportFile( $event.target.files[0] )"/>
                </div>
              </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
        <div class="col-xl-10 col-lg-9 col-sm-8">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">2. Vista previa</h4>
              <CustomTable
                  v-if="import_data"
                  :url="table.url"
                  :fields="table.fields"
                  ref="previewImportTable"
                  :show-view="false"
                  :show-edit="false"
                  :showSoftDelete="false"
                >
                </CustomTable>
                <div v-if="import_data && import_data?.process_status < 2" class="text-center">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Cargando... {{ import_data?.import_rows_count }}</strong>
                </div>
            </div>
            <!-- end card-body -->
          </div>
        </div>
      </div>
    </Modal>
</template>